import * as bootstrap from 'bootstrap';

function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

document.addEventListener('DOMContentLoaded', () => {
    if(isMobile()) {
        document.querySelector('.row-go-down').remove();
    }
});